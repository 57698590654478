<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">


				<el-button size="small" icon="el-icon-download" type="primary" @click="exportPdfs"
					style="margin-left: 20px;">导出实习手册
				</el-button>
				<el-button size="small" icon="el-icon-download" type="primary" @click="exportPdfs_xf"
					style="margin-left: 20px;">导出巡访手册
				</el-button>

				<el-button size="small" icon="el-icon-refresh" @click="resetPwd" type="warning"
					style="margin-left: 20px;">重置密码
				</el-button>



			</div>

			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>人员信息</el-breadcrumb-item>
					<el-breadcrumb-item>教师信息</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

		</div>
		<div style="height: 85%;">

			<div style="max-width:1000px; margin:0 auto">
				<el-card>
					<el-form :model="formData" v-if="formData.id" ref="adminUserInfo" label-width="90px">

						<el-row>



							<el-col :span="12">
								<el-form-item label="姓名:">
									<el-input class="elInput" disabled v-model="formData.username" placeholder="请输入姓名">
									</el-input>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="职工号:">
									<el-input class="elInput" disabled v-model="formData.loginname"
										placeholder="请输入职工号">
									</el-input>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="手机号:">
									<el-input class="elInput" v-model="formData.phone" placeholder="请输入手机号"></el-input>
								</el-form-item>
							</el-col>


							<el-col :span="12">
								<el-form-item label="座机号">
									<el-input class="elInput" v-model="formData.mobile" placeholder="请输入座机号"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="邮箱">
									<el-input class="elInput" v-model="formData.email" placeholder="请输入邮箱"></el-input>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="性别:">
									<el-radio-group size="small" v-model="formData.sex">
										<el-radio :label="1">男</el-radio>
										<el-radio :label="2">女</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>



						</el-row>

						<el-row>

							<el-col :span="12">
								<el-form-item label="部门职位:" prop="dept_id">


									<el-tag v-for="(item, idx) in formData.dept_roles" :key="idx"
										style="margin-right:10px">
										{{ (item.name ? item.name : '未分配部门') + '/' + (item.role_name ? item.role_name : '职员') }}</el-tag>



								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="系统角色:" prop="locked">
									<el-tag v-for="(item, idx) in formData.sys_roles" :key="idx"
										style="margin-right:10px">
										{{ item.role_name }}</el-tag>


								</el-form-item>
							</el-col>
						</el-row>


						<el-row>
							<el-col :span="22">
								<div style="text-align:right">
									<el-button @click="saveInfo" size="small" icon="el-icon-check" type="primary"
										style="margin-left: 20px;">
										保存
									</el-button>
								</div>

							</el-col>


						</el-row>

					</el-form>
				</el-card>
			</div>
		</div>

	</div>
</template>
<script>

export default {
	name: 'dept',
	components: {

	},
	data() {
		return {
			visible: false,
			isShowModal: false,
			isShowModalAuth: false,
			importTeacherShow: false,
			modalTitle: '添加',
			DeptUserDialog: false,
			showDeptroleManage: false,
			activeRoleID: "",
			formData: {},
			fgxz: [],
			activeName: 'CN',
			tableData: [],

			teacherList: [],
			editUser: {},
			deptId: null,
			editDeptRole: {},
			deptRoleList: [],
			isShowDeptRole: false,
			biye: 0,

			page: {

			},
			searchForm: {

				ktype: "username",
				keyword: ""
			},
			multipleSelection: [],
			treeData: [],
			ckdTreeData: [],
			firstCascaderProps: {
				label: "name",
				children: "children",
			},
			defaultProps: {
				label: 'name',
				value: 'id',
				children: 'children',
				emitPath: false,
				checkStrictly: true
			},
			DataAuth: [],
			showTeacherInfo: false,
			teacher_id: 0
		}
	},

	mounted() {
		// this.getTree();
		// this.getDeptRoles()
		this.getTeachers()
	},
	methods: {



		getTeachers() {
			let params = {
				page: this.page.current_page,
				pagesize: this.page.per_page,
				ktype: this.searchForm.ktype,
				keyword: this.searchForm.keyword,
				dept_id: this.searchForm.keyword ? 0 : this.deptId,
				biye: this.biye
			};


			// sys_dept_user_related_list
			this.$http.post("/api/get_teacher_info").then((res) => {
				this.formData = res.data;

			});
		},
		saveInfo() {


			this.$http.post("/api/t_sch_teachers_edit", this.formData).then((res) => {
				this.$message.success("修改成功")

			});
		},
		resetPwd(e) {

			this.$confirm('如果系统参数中配置了默认密码则重置为该密码，否则为手机号后六位，确认重置?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/reset_teache_pwd", {
					id: this.formData.id
				}).then(res => {
					this.$message.success("密码已重置")
				})
			}).catch(() => {

			});




		},
		exportPdfs() {
			let params = {
				"stuArr": [{
					"id": this.formData.id,
					"name": this.formData.username,
				}],
				"pmid": window.pmid,
				"latest": 1
			}
			this.$http.post(this.$pdfUrl + "/exportTchPdf", params).then(res => {
				this.$message({
					type: 'success',
					message: '生成成功!'
				});
				window.open(res.data.url, "_blank")
			})
		},
		exportPdfs_xf(row) {
			let params = {
				"stuArr": [{
					"id": this.formData.id,
					"name": this.formData.username,
				}],
				"pmid": window.pmid,
				"latest": 1
			}
			this.$http.post(this.$pdfUrl + "/exportTchPdf_xf", params).then(res => {
				this.$message({
					type: 'success',
					message: '生成成功!'
				});
				window.open(res.data.url, "_blank")
			})
		},
	}
}
</script>
<style scoped lang="less" type="text/less">
.app-container {

	.treeContainer {
		height: 100%;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
		border: 1px solid rgba(220, 223, 230, 0.6);
		margin-right: 20px;
		padding-right: 10px;

		.treeTitle {
			height: 40px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #409eff;
			padding-left: 6px;
		}
	}
}

.nodetag {
	display: inline-block;
	padding: 0 5px;
	font-size: 12px;
	transform: scale(0.8, 0.8);
	background-color: #409eff;
	color: #fff;
	border-radius: 4px;
}
</style>
